'use client';

import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import useRollbar from '@/hooks/useRollbar';
import useLocalStorage from '@/hooks/useLocalStorage';
import ObjectId from 'bson-objectid';
import {
  CHAT_FILTERS,
  DASHBOARD_HAS_DATA,
  FLOATING_THREADS,
  PUBLIC_ASSISTANT_ID,
  PUBLIC_TEST_THREAD_ID,
  PUBLIC_THREAD_ID,
} from '@/utils/storageKeys';
import { useParams, usePathname } from 'next/navigation';
import { useStore } from '@/stores/store';
import { WsErrorObject, handleEmitCallbackError } from '@/utils/error';
import { useThreadUsers } from '@/hooks/useThreadUsers';

import {
  Assistant,
  Thread,
  FloatingThread,
  Tag,
  MessageStatus,
  ThreadUserArgs,
  Message,
  ThreadTagsArgs,
  MissionAddQuestionArgs,
  Mission,
  ReducedThread,
} from '@/typings/backend';
import { GetMoreReducedThreadsArgs, WebsocketContext } from './WebsocketProvider';
import { User } from '@/typings/user';
import { getSocket } from '@/utils/socket';
import { Socket } from 'socket.io-client';
import { mergeArraysById as mergeObjects } from '@/utils/mergeObjects';
import { setCookie } from 'cookies-next';
import { isEqual } from 'lodash';

type CallbackData<T> = T | { error: WsErrorObject };

export const createId = () => new ObjectId().toHexString();

const THREAD_KEY_FIELD_OVERRIDES = {
  thoughts: 'messageId',
  missions: 'missionId',
  knowledgeUsed: 'title',
};

const WebsocketProviderReal: React.FC<
  PropsWithChildren<{ isPublic?: boolean; isTest?: boolean }>
> = ({ children, isPublic = false, isTest }) => {
  const rollbar = useRollbar();

  const [floatingThreads, setFloatingThreads] = useLocalStorage({
    key: FLOATING_THREADS,
    initialValue: [] as FloatingThread[],
  });

  const [users, setUsers] = useState<User[] | null>(null);
  const [attendantUsers, setAttendantUsers] = useState([] as User[]);
  const [assistants, setAssistants] = useState<Assistant[] | null>(null);
  const [threads, setThreads] = useState<Thread[] | null>(null);
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [reducedThreads, setReducedThreads] = useState<Partial<Thread>[] | null>(null);
  const [loadingReducedThreads, setLoadingReducedThreads] = useState<boolean>(false);
  const [loadingThreads, setLoadingThreads] = useState<boolean>(false);

  const [hasMoreReducedThreads, setHasMoreReducedThreads] = useState<boolean>(true);

  const disableOnThreadCallback = useRef(false);
  const shouldGetFloatingThreadsAgain = useRef(false);
  // This ref ise used to reconnect the threads if a disconnect happens
  const shouldGetConversationThreads = useRef(false);

  const user = useStore((store) => store.user);
  const organization = useStore((store) => store.organization);
  const updateData = useStore((store) => store.updateData);

  const params = useParams<{ assistantSlug?: string; threadId?: string }>();

  // const pathname = usePathname();

  const organizationId = organization?.id;
  const assistantSlug = params?.assistantSlug;

  const isLoading = loadingThreads || loadingReducedThreads;

  const socket = useRef<Socket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const listeners = useRef<{ [key: string]: (data: any) => void }>({}); // eslint-disable-line @typescript-eslint/no-explicit-any

  const [isConnected, setIsConnected] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useThreadUsers(threads ?? []);

  const onConnect = () => {
    console.log('Socket connected');
    setIsConnected(true);
  };

  const onDisconnect = () => {
    console.log('Socket disconnected');
    setIsConnected(false);
    shouldGetFloatingThreadsAgain.current = true;
    shouldGetConversationThreads.current = true;
  };

  function onError(data: { error: WsErrorObject }) {
    console.log('Socket error', data);

    // Only way to join a thread that does not exist is trying to do it from the floatingThreads on getThreads method
    if (data.error.message.includes('ObjectId not found on string: thread:')) {
      const threadId = data.error.message.split('thread:')?.[1];
      if (threadId) {
        setFloatingThreads((state) => state.filter((s) => s.threadId !== threadId));
        setThreads((state) => (state ? state.filter((s) => s.id !== threadId) : []));
      }
      setLoadingThreads(false);
    } else {
      handleEmitCallbackError(data, 'Error on on.error event');
    }
  }

  function startSocketConnection(newSocket: Socket) {
    console.log('Starting socket connection');

    // Setting base listeners before connection to avoid any missing event
    newSocket.on('connected', onConnected);
    newSocket.on('connect', onConnect);
    newSocket.on('disconnect', onDisconnect);
    newSocket.on('error', onError);

    // Connecting
    newSocket.connect();
  }

  function stopSocketConnection(newSocket: Socket) {
    console.log('Stopping socket connection');
    newSocket.disconnect();

    newSocket.off('connected');
    newSocket.off('connect');
    newSocket.off('disconnect');
    newSocket.off('error');

    if (reconnectTimeoutRef.current) clearTimeout(reconnectTimeoutRef.current);

    console.log('Socket connection closed');
  }

  function emitEvent<T>(
    eventName: string,
    args: Record<string, unknown>,
    callback?: (data: T) => void,
  ) {
    console.log('Emitting event:', eventName);
    if (!socket.current) {
      console.error('Socket not initialized');
      return;
    }
    socket.current.emit(eventName, args, callback);
  }

  function emitRequestData<T>(
    channel: string,
    params: Record<string, unknown>,
    callback?: (data: T) => void,
  ) {
    console.log('Emitting requestData');
    if (!socket.current) {
      rollbar.error('Failed to emit request data: Socket not initialized');
      return;
    }
    socket.current.emit('requestData', { channel, params }, callback);
  }

  function resetSocket() {
    socket.current?.close();
    socket.current?.removeAllListeners();
    socket.current = null;
  }

  function joinChannel<T>(
    channelName: string,
    callback: (data: T) => void,
    callbackOnJoin: boolean,
    listen: boolean,
    listenerName: string | null,
    afterJoin?: (data: T) => void,
    otherParams?: Record<string, unknown>,
  ) {
    console.log(`Joining channel: ${channelName}`, {
      listen,
      callbackOnJoin,
      listenerName,
      otherParams,
    });
    if (!socket.current) {
      console.error('Socket not initialized');
      return () => void 0;
    }
    if (listen) {
      if (!listenerName) {
        throw new Error('listenerName is required when listen is true');
      }
      if (!(listenerName in listeners.current)) {
        socket.current.on(listenerName, callback);
        listeners.current[listenerName] = callback;
      }
    }

    socket.current.emit(
      'joinChannel',
      { channel: channelName, ...(otherParams ? { params: otherParams } : {}) },
      callbackOnJoin || afterJoin
        ? (data: T) => {
            if (callbackOnJoin) callback(data);
            if (afterJoin) afterJoin(data);
          }
        : undefined,
    );
    console.log(`Joined channel: ${channelName}`);

    return () => {
      if (listen && listenerName && socket.current) {
        socket.current.off(listenerName);
        delete listeners.current[listenerName];
      }
    };
  }

  function listenChannel<T>(
    channelName: string,
    callback: (data: T) => void,
    listenerName: string,
  ) {
    console.log(`Listening channel: ${channelName}`);
    if (!socket.current) {
      console.error('Socket not initialized');
      return () => void 0;
    }

    if (!(listenerName in listeners.current)) {
      socket.current.on(listenerName, callback);
      listeners.current[listenerName] = callback;
    }

    socket.current.emit('listenChannel', { channel: channelName });

    return () => {
      if (listenerName && socket.current) {
        socket.current.off(listenerName);
        delete listeners.current[listenerName];
      }
    };
  }

  function onConnected(data: {
    connected: boolean;
    organizationId: string;
    assistant?: Assistant[];
  }) {
    console.log('onConnected: ', data);

    const assistant = data.assistant?.[0];
    const organizationId = data.organizationId;

    setIsInitialized(true);

    if (isPublic) {
      const publicThreadId = localStorage.getItem(PUBLIC_THREAD_ID);
      const publicTestThreadId = localStorage.getItem(PUBLIC_TEST_THREAD_ID);
      const publicAssistantId = localStorage.getItem(PUBLIC_ASSISTANT_ID);
      const threadId = isTest ? publicTestThreadId : publicThreadId;

      if (!assistant) {
        rollbar.error('No assistant received on public route with slug', {
          assistantSlug,
          dataReceived: data,
        });
        return;
      }

      setAssistants([assistant]);

      const isSameAssistant = assistant.id === publicAssistantId;

      const orgId = assistant.organizationId;
      if (threadId && isSameAssistant && publicAssistantId && orgId) {
        joinChannel(
          `organization:${orgId}:messageStatus`,
          onMessageStatus,
          false,
          true,
          'messageStatus',
        );
        getThreadById(threadId);
      } else {
        console.log('Public thread not found', {
          threadId,
          publicAssistantId,
        });
        setThreads([]);
        console.log('A new chat will be started');
      }
    } else {
      getFloatingThreads();

      const filters = JSON.parse(localStorage.getItem(CHAT_FILTERS) || '{}') as Record<
        string,
        string[]
      >;

      const filter = Object.entries(filters).reduce((prev, [key, value]) => {
        if (value.length > 0) {
          return { ...prev, [key]: value };
        }
        return prev;
      }, {});

      lastFilterRef.current = filter;

      joinChannel(
        `organization:${organizationId}:reducedThreads`,
        onReducedThreads,
        true,
        true,
        'reducedThreads',
        undefined,
        { filter },
      );

      joinChannel(
        `organization:${organizationId}:assistants`,
        onAssistants,
        true,
        true,
        'assistants',
      );
      joinChannel(`organization:${organizationId}:users`, onUsers, true, true, 'users');
      joinChannel(`organization:${organizationId}:tags`, onTags, true, true, 'tags');
      joinChannel(
        `organization:${organizationId}:messageStatus`,
        onMessageStatus,
        false,
        true,
        'messageStatus',
      );
    }
  }

  function onAssistants(data: { assistants: Assistant[] }) {
    console.log('onAssistants: ', data);

    const assistantsReceived = data.assistants || [];
    setAssistants((state) => mergeObjects(state ?? [], assistantsReceived, 'id'));

    if (!isPublic && assistantsReceived && assistantsReceived?.length > 0) {
      const hasChannels = assistantsReceived.some(
        (assistant) => assistant.channels && assistant.channels.length > 0,
      );
      if (hasChannels) {
        // User has at least one assistant fully setup. So it has something to show on the dashboard
        const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
        setCookie(DASHBOARD_HAS_DATA, 'true', { maxAge });
      }
    }
  }

  const onReducedThreads = (data: { reducedThreads: Partial<Thread>[] }) => {
    console.log('onReducedThreads: ', data);

    const reducedThreadsReceived = data.reducedThreads || [];

    setReducedThreads((state) =>
      mergeObjects(state ?? [], reducedThreadsReceived, 'id', THREAD_KEY_FIELD_OVERRIDES),
    );
    setHasMoreReducedThreads(reducedThreadsReceived.length > 0);
    setLoadingReducedThreads(false);
  };

  const onThreads = useCallback(
    (data: { threads: Thread[] }) => {
      if (disableOnThreadCallback.current) {
        disableOnThreadCallback.current = false;
        return;
      }

      console.log('onThreads: ', data);
      if ('threads' in data) {
        const threadsReceived = data.threads;
        if (threadsReceived.length === 1) {
          const thread = threadsReceived[0];
          const threadExists = threads?.some((t) => t.id === thread.id);
          if (threadExists) thread.isReady = true;
          setThreads((state) =>
            mergeObjects(state ?? [], [thread], 'id', THREAD_KEY_FIELD_OVERRIDES),
          );
        } else {
          setThreads((state) =>
            mergeObjects(state ?? [], threadsReceived, 'id', THREAD_KEY_FIELD_OVERRIDES),
          );
        }

        if (loadingThreads) setLoadingThreads(false);
      } else {
        handleEmitCallbackError(data, 'Error on emit joinChannel on public interface');
      }
    },
    [threads],
  );

  const onUsers = useCallback((data: { users: User[] }) => {
    console.log('onUsers: ', data);
    const users = data.users || [];
    setUsers((state) => mergeObjects(state ?? [], users, 'id'));
    const attendantUsers: User[] = users.filter(
      (user) => user && user.attendantAreas && user.attendantAreas.length > 1,
    );
    attendantUsers.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    setAttendantUsers(attendantUsers);
    console.log('attendantUsers: ', attendantUsers);
  }, []);

  const onTags = useCallback((data: { tags: Tag[] }) => {
    console.log('onTags: ', data);
    const tags = data.tags || [];
    setTags((state) => mergeObjects(state ?? [], tags, 'id'));
  }, []);

  const onMessageStatus = useCallback(
    (data: { messageStatus: { messageId: string; threadId: string; status: MessageStatus } }) => {
      console.log('onMessageStatus: ', data);
      const messageStatus = data.messageStatus || {};
      setThreads((state) => {
        if (!state) return null;

        return state.map((t) =>
          t.id === messageStatus.threadId
            ? {
                ...t,
                messages: (t.messages || []).map((message) =>
                  message.id === messageStatus.messageId
                    ? { ...message, status: messageStatus.status }
                    : message,
                ),
              }
            : t,
        );
      });
    },
    [threads],
  );

  useEffect(() => {
    if (isPublic && !assistantSlug) {
      rollbar.critical('No assistantSlug provided on connection at public chat interface');
      return;
    }
    if (!isPublic && !organizationId) {
      rollbar.critical('No organizationId provided on connection');
      return;
    }

    if (assistantSlug) {
      console.log('Creating socket connection for assistant:', assistantSlug);
      socket.current = getSocket({ assistantSlug: assistantSlug.replace('teste-', '') });
      startSocketConnection(socket.current);

      return () => {
        if (socket.current) stopSocketConnection(socket.current);
        resetSocket();
      };
    }

    if (organizationId) {
      console.log('Creating socket connection for organization:', organizationId);
      socket.current = getSocket({ organizationId });
      startSocketConnection(socket.current);

      return () => {
        if (socket.current) stopSocketConnection(socket.current);
        resetSocket();
      };
    }
  }, [organizationId, assistantSlug]);

  useEffect(() => {
    if (!isConnected) return;
    return () => {
      console.log('Unsubscribing from all channels');
      Object.keys(listeners.current).forEach((listener) => {
        socket.current?.off(listener);
        delete listeners.current[listener];
      });
      setIsInitialized(false);
    };
  }, [isConnected]);

  const getThreadById = (threadId: string) => {
    setLoadingThreads(true);
    joinChannel(`thread:${threadId}`, onThreads, true, true, 'thread', () =>
      setLoadingThreads(false),
    );
  };

  const getFloatingThreads = useCallback(() => {
    console.log('----- Calling getFloatingThreads ------');
    if (!shouldGetFloatingThreadsAgain.current) {
      setLoadingThreads(true);
    }

    shouldGetFloatingThreadsAgain.current = false;

    if (floatingThreads.length > 0) {
      const ids = floatingThreads.map((t) => t.threadId).filter(Boolean);
      getThreadsByIds(ids);
    } else {
      setLoadingThreads(false);
    }
  }, [floatingThreads]);

  const lastFilterRef = useRef<GetMoreReducedThreadsArgs>({});

  const getMoreReducedThreads = ({ lastMessageBefore, ...filters }: GetMoreReducedThreadsArgs) => {
    console.log('----- Calling getMoreReducedThreads ------', { lastMessageBefore, ...filters });
    setLoadingReducedThreads(true);

    if (!isEqual(lastFilterRef.current, filters)) {
      lastFilterRef.current = filters;
      setReducedThreads([]);
      setLoadingReducedThreads(true);
      setHasMoreReducedThreads(true);
    }

    if (!organizationId) {
      rollbar.error('User trying to join channel without organizationId', { user });
      setLoadingReducedThreads(false);
      return;
    }

    const userIds = filters.userIds?.filter((id) => !!id);
    const assistantIds = filters.assistantIds?.filter((id) => !!id);
    const tagsIds = filters.tagIds?.filter((id) => !!id);

    const filter = { userIds, tagsIds, assistantIds, lastMessageBefore };

    emitRequestData<ReducedThread[]>('reducedThreads', { filter, organizationId });
  };

  const getThreadsByIds = (ids: string[]) => {
    console.log('----- Calling getThreadsByIds ------', ids);

    if (!organizationId) {
      rollbar.error('User trying to join channel without organizationId', { user });
      return;
    }

    if (!socket.current) {
      rollbar.error('Socket not initialized');
      return;
    }

    const threadsIds = ids.filter((id) => !!id);

    if (!listeners.current['threads']) {
      listenChannel(`organization:${organizationId}:threads`, onThreads, 'threads');
    }

    emitRequestData<{ threads: Thread[] }>('threads', { threadsIds, organizationId });
  };

  const startNewChat = useCallback(
    ({
      assistantId,
      makeChatFloating,
      onSuccess,
      organizationId,
    }: {
      assistantId: string;
      makeChatFloating?: boolean;
      onSuccess?: (thread: Thread) => void;
      organizationId?: string;
    }) => {
      console.log('Starting new chat', { assistantId, makeChatFloating });
      if (makeChatFloating) {
        setFloatingThreads([...floatingThreads, { assistantId, id: createId() } as FloatingThread]);
        return;
      }

      const threadId = createId();

      const conversationType = !isPublic || isTest ? 'personAssistantTest' : 'personAssistant';
      const source: Thread['source'] = isPublic ? 'webUser' : 'webAdmin';
      const createdByUserId = isPublic ? undefined : user?.id;

      emitEvent(
        'threadCreate',
        {
          assistantId,
          threadId,
          conversationType,
          source,
          createdByUserId,
          userAgent: navigator.userAgent,
        },
        (data: CallbackData<{ thread: Thread }>) => {
          if ('thread' in data) {
            if (isPublic) {
              if (isTest) {
                localStorage.setItem(PUBLIC_TEST_THREAD_ID, threadId);
              } else {
                localStorage.setItem(PUBLIC_THREAD_ID, threadId);
              }
              localStorage.setItem(PUBLIC_ASSISTANT_ID, assistantId);
            }

            setThreads((state) =>
              mergeObjects(
                state ?? [],
                [{ ...data.thread, opened: true, conversationType }],
                'id',
                THREAD_KEY_FIELD_OVERRIDES,
              ),
            );

            onSuccess?.(data.thread);
            joinChannel(`thread:${threadId}`, onThreads, false, true, 'thread');
            joinChannel(
              `organization:${organizationId}:messageStatus`,
              onMessageStatus,
              false,
              true,
              'messageStatus',
            );
          } else {
            handleEmitCallbackError(data, 'Error on emit threadCreate callback');
            setFloatingThreads((threads) => threads.filter((t) => t.threadId !== threadId));
          }
        },
      );
    },
    [floatingThreads, threads, isPublic],
  );

  const addThreadUser = useCallback((data: ThreadUserArgs, onError?: () => void) => {
    emitEvent('threadUserAdd', data, (data: CallbackData<{ thread: Thread }>) => {
      if ('thread' in data) {
        const threadsReceived = data.thread;

        setThreads((state) =>
          mergeObjects(state ?? [], [threadsReceived], 'id', THREAD_KEY_FIELD_OVERRIDES),
        );
      } else {
        handleEmitCallbackError(data, 'Error on threadUserAdd callback');

        onError?.();
      }
    });
  }, []);

  const addThreadTags = useCallback((data: ThreadTagsArgs, onError?: () => void) => {
    emitEvent('addThreadTags', data, (data: CallbackData<{ thread: Thread }>) => {
      if ('thread' in data) {
        const threadsReceived = data.thread;

        setThreads((state) =>
          mergeObjects(state ?? [], [threadsReceived], 'id', THREAD_KEY_FIELD_OVERRIDES),
        );
      } else {
        handleEmitCallbackError(data, 'Error on addThreadTags callback');

        onError?.();
      }
    });
  }, []);

  const deleteThreadTag = useCallback((data: ThreadTagsArgs, onError?: () => void) => {
    emitEvent('deleteThreadTag', data, (data: CallbackData<{ thread: Thread }>) => {
      if ('thread' in data) {
        const threadsReceived = data.thread;

        setThreads((state) =>
          mergeObjects(state ?? [], [threadsReceived], 'id', THREAD_KEY_FIELD_OVERRIDES),
        );
      } else {
        handleEmitCallbackError(data, 'Error on deleteThreadTag callback');

        onError?.();
      }
    });
  }, []);

  const removeThreadUser = useCallback((data: ThreadUserArgs, onError?: () => void) => {
    emitEvent('threadUserRemove', data, (data: CallbackData<{ thread: Thread }>) => {
      if ('thread' in data) {
        const threadsReceived = data.thread;

        setThreads((state) =>
          mergeObjects(state ?? [], [threadsReceived], 'id', THREAD_KEY_FIELD_OVERRIDES),
        );
      } else {
        handleEmitCallbackError(data, 'Error on threadUserRemove callback');

        onError?.();
      }
    });
  }, []);

  const deletePublicThread = useCallback((threadId: string) => {
    localStorage.removeItem(PUBLIC_THREAD_ID);
    setThreads((threads) => {
      if (!threads) return null;

      return threads.filter((t) => t.id !== threadId);
    });
  }, []);

  const pathname = usePathname();

  useEffect(() => {
    if (!loadingThreads && pathname?.startsWith('/conversas') && params) {
      const id = params.threadId;

      if (id && threads && !threads.some((t) => t.id === id)) {
        getThreadById(id);
      }
    }
  }, [params, pathname, threads, loadingThreads]);

  function sendMessage({
    message,
    thread,
    assistant,
    floatingThreadId,
  }: {
    message: string;
    thread?: Thread;
    assistant: Assistant;
    floatingThreadId?: string;
  }) {
    if (!thread || !thread?.id) {
      disableOnThreadCallback.current = true;
      startNewChat({
        organizationId: assistant.organizationId,
        assistantId: assistant.id,
        makeChatFloating: false,
        onSuccess: (thread) => {
          // Starting conversation from the "Conversar" button on dashboard
          if (floatingThreadId) {
            setFloatingThreads((state) =>
              state.map((s) => (s.id === floatingThreadId ? { ...s, threadId: thread.id } : s)),
            );
          }
          sendMessage({ message, thread, assistant });
        },
      });
      return;
    }

    const threadId = thread.id;

    const payload = {
      messageId: createId(),
      content: message,
      role:
        isPublic ||
        thread.conversationType === 'personAssistantTest' ||
        (thread.conversationType === 'personUserTest' && user?.id === thread.createdByUserId)
          ? 'user'
          : 'assistant',
      threadId: threadId,
      userId:
        isPublic ||
        (thread.conversationType === 'personUserTest' && user?.id === thread.createdByUserId)
          ? null
          : user?.id,
    };

    const newMessageData = {
      id: payload.messageId,
      content: payload.content,
      role: payload.role,
      createdAt: new Date().toISOString(),
      status: 'sent',
    } as Message;
    setThreads((state) => {
      return (state ?? []).map((thread) =>
        thread.id === threadId
          ? {
              ...thread,
              lastMessageAt: new Date().toISOString(),
              messages: [...(thread.messages || []), newMessageData],
            }
          : thread,
      );
    });

    emitEvent('createMessage', payload, (data: { error: WsErrorObject }) => {
      handleEmitCallbackError(data, 'Error on createMessage callback');
      setThreads((state) => {
        return (state ?? []).map((thread) =>
          thread.id === threadId
            ? {
                ...thread,
                lastMessageAt: new Date().toISOString(),
                messages: (thread.messages || []).filter((m) => m.id !== newMessageData.id),
              }
            : thread,
        );
      });
    });
  }

  const toggleOpenChat = useCallback(
    (threadId: string, isOpen?: boolean) => {
      setThreads((threads) => {
        if (!threads) return null;

        return threads.map((t) =>
          t.id === threadId ? { ...t, opened: isOpen !== undefined ? isOpen : !t.opened } : t,
        );
      });
    },
    [threads],
  );

  const closeFloatingThread = useCallback(
    (id?: string) => {
      if (!id) {
        return;
      }
      setFloatingThreads(floatingThreads.filter((t) => t.id !== id));
    },
    [threads, floatingThreads],
  );

  const missionAddQuestion = useCallback((data: MissionAddQuestionArgs, onError?: () => void) => {
    emitEvent('missionAddQuestion', data, (data: CallbackData<{ updatedMission: Mission }>) => {
      if ('updatedMission' in data) {
        const updatedMission = data.updatedMission;
        updateData(
          'missions',
          (state: Mission[] | null) =>
            state?.map((m) => (m.id === updatedMission.id ? updatedMission : m)) || [],
        );
      } else {
        handleEmitCallbackError(data, 'Error on missionAddQuestion callback');
        onError?.();
        return;
      }
    });
  }, []);

  const cleanSocket = () => {
    setIsConnected(false);
    setIsInitialized(false);
    setUsers(null);
    setAttendantUsers([]);
    setAssistants(null);
    setThreads(null);
    setTags(null);
    setReducedThreads(null);
    setLoadingReducedThreads(false);
    setLoadingThreads(false);
    setHasMoreReducedThreads(true);
    resetSocket();
  };

  return (
    <WebsocketContext.Provider
      value={{
        isConnected: isConnected,
        isReady: isInitialized,
        assistants,
        threads,
        reducedThreads,
        getMoreReducedThreads,
        loadingReducedThreads,
        hasMoreReducedThreads,
        getThreadsByIds,
        tags: tags ? tags.filter((tag) => !!tag.shortTitle) : null,
        floatingThreads,
        loadingThreads: isLoading,
        users,
        addThreadUser,
        removeThreadUser,
        addThreadTags,
        deleteThreadTag,
        startNewChat,
        sendMessage,
        toggleOpenChat,
        closeFloatingThread,
        cleanSocket,
        setFloatingThreads,
        setAssistants,
        deletePublicThread,
        attendantUsers,
        joinChannel,
        setReducedThreads,
        missionAddQuestion,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export const useWebsocketReal = () => React.useContext(WebsocketContext);
export default WebsocketProviderReal;
