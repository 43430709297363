import { Assistant, OrganizationUser, Tag } from '@/typings/backend';
import { User } from '@/typings/user';

export const mockCoreTags: Tag[] = [
  {
    id: '98ac7d2f0000000000afb54d',
    title: 'Suporte Técnico',
    shortTitle: 'Suporte',
    description: 'Tags relacionadas a problemas técnicos e suporte de TI.',
    createdAt: '2023-10-01T10:30:45Z',
    organizationId: 'org123',
    threadIds: ['thread123', 'thread456', 'thread789'],
  },
  {
    id: '98ad12bc9fd9adcbf8765412',
    title: 'RH',
    shortTitle: 'RH',
    description: 'Chats relacionados a consultas e negociações comerciais.',
    createdAt: '2023-10-02T11:15:30Z',
    organizationId: 'org123',
    threadIds: ['thread987', 'thread654', 'thread321'],
  },
  {
    id: '66cf69e0a863fb56075342e5',
    title: 'Reclamações e Feedback',
    shortTitle: 'Qualificado',
    description: 'Conversas sobre reclamações de clientes ou sugestões de melhorias.',
    createdAt: '2023-09-15T14:20:10Z',
    organizationId: 'org456',
    threadIds: ['thread852', 'thread741'],
  },
  {
    id: '66c7572f0000000000afb13f',
    title: 'Consultoria Jurídica',
    shortTitle: 'Jurídico',
    description: 'Classificação para questões relacionadas a consultoria legal.',
    createdAt: '2023-08-20T09:45:05Z',
    organizationId: 'org789',
    threadIds: ['thread963', 'thread753', 'thread159'],
  },
  {
    id: '66f1b257d0aba90c794c82fe',
    title: 'Financeiro e Cobranças',
    shortTitle: 'Financeiro',
    description: 'Conversas sobre questões financeiras, faturas e cobranças.',
    createdAt: '2023-07-10T16:55:25Z',
    organizationId: 'org123',
    threadIds: ['thread951', 'thread753', 'thread159'],
  },
];

export const mockCoreUsers: User[] = [
  {
    id: 'user123',
    email: 'maria.silva@example.com',
    name: 'Maria Silva',
    shortName: 'Maria',
    phoneNumber: '+5511998765432',
    superRole: null,
    notificationTokens: ['token_12345'],
    knowledgeAreas: 'Suporte Técnico, Infraestrutura',
    attendantAreas: 'Cliente ativo',
    createdAt: '2022-05-15T10:20:30Z',
    organizations: [],
  },
  {
    id: 'user456',
    email: 'joao.pereira@example.com',
    name: 'João Pereira',
    shortName: 'João',
    superRole: 'SUPER_ADMIN',
    notificationTokens: ['token_54321'],
    knowledgeAreas: 'Vendas, Marketing',
    attendantAreas: 'Comercial',
    createdAt: '2021-01-15T09:30:00Z',
    organizations: [],
  },
  {
    id: 'user789',
    email: 'claudia.mendes@example.com',
    name: 'Cláudia Mendes',
    shortName: 'Cláudia',
    phoneNumber: '+5511987654321',
    superRole: null,
    notificationTokens: ['token_98765'],
    knowledgeAreas: 'Consultoria Jurídica',
    attendantAreas: 'Jurídico',
    createdAt: '2020-07-12T14:40:00Z',
    organizations: [],
  },
];

export const mockCoreOrganizationUsers: OrganizationUser[] = [
  {
    id: 'orgUser1',
    threadId: 'thread123',
    createdAt: '2023-10-01T12:34:56Z',
    user: mockCoreUsers[0],
    userId: mockCoreUsers[0].id,
    role: 'manager',
    reason: 'Atribuído para liderar a equipe de marketing',
    readAt: '2023-10-02T14:20:30Z',
    deletedAt: null,
    escalateReason: null,
    userJustification: null,
  },
  {
    id: 'orgUser123',
    threadId: 'thread123',
    createdAt: '2023-10-01T12:34:56Z',
    user: mockCoreUsers[1],
    userId: mockCoreUsers[1].id,
    role: 'support',
    reason: 'Atribuída para questões de suporte técnico.',
    readAt: '2023-10-01T14:00:00Z',
    deletedAt: null,
    escalateReason: null,
    userJustification: null,
  },
  {
    id: 'orgUser456',
    threadId: 'thread456',
    createdAt: '2023-09-20T08:22:10Z',
    user: mockCoreUsers[2],
    userId: mockCoreUsers[2].id,
    role: 'manager',
    reason: 'Responsável pelas negociações comerciais.',
    readAt: null,
    deletedAt: null,
    escalateReason: 'Pedido escalado para supervisão devido à complexidade.',
    userJustification: 'Cliente solicitou desconto especial.',
  },
  {
    id: 'orgUser789',
    threadId: 'thread789',
    createdAt: '2023-08-10T10:15:30Z',
    user: mockCoreUsers[2],
    userId: mockCoreUsers[2].id,
    role: 'PARTICIPANT',
    reason: 'Atribuída para consultoria jurídica.',
    readAt: '2023-08-10T12:00:00Z',
    deletedAt: null,
    escalateReason: null,
    userJustification: null,
  },
];

export const mockCoreAssistants: Assistant[] = [
  {
    id: 'assistant-1',
    name: 'Camila Torres',
    slug: 'helperbot',
    active: true,
    voice: {
      provider: 'Google',
      voiceName: 'en-US-Wavenet-D',
    },
    prompt: 'Olá! Em que posso ajudar você hoje?',
    organizationId: 'org-1',
    defaultMissionId: 'mission-1',
    organization: {
      id: 'org-1',
      name: 'Tech Solutions',
      active: true,
      createdAt: '2023-01-15T08:00:00Z',
    },
    createdAt: '2023-01-15T08:00:00Z',
    updatedAt: '2023-01-15T08:00:00Z',
  },
  {
    id: 'assistant-2',
    name: 'Atendimento Moinho',
    slug: 'helperbot',
    active: true,
    voice: {
      provider: 'Google',
      voiceName: 'en-US-Wavenet-D',
    },
    prompt: 'Olá! Em que posso ajudar você hoje?',
    organizationId: 'org-1',
    defaultMissionId: 'mission-1',
    organization: {
      id: 'org-1',
      name: 'Tech Solutions',
      active: true,
      createdAt: '2023-01-15T08:00:00Z',
    },
    createdAt: '2023-01-15T08:00:00Z',
    updatedAt: '2023-01-15T08:00:00Z',
  },
];
