import { Activity } from './activity';
import { User } from './user';

export type ThreadUserArgs = {
  userId: string;
  threadId: string;
  organizationId: string;
};

export type ThreadTagsArgs = {
  tagId: string;
  threadId: string;
  role?: string;
  reason?: string;
  taggerId?: string;
};

export type Assistant = {
  id: string;
  name: string;
  slug: string;
  active: boolean;
  voice: {
    provider: string;
    voiceName: string;
  };
  prompt: string;
  organizationId: string;
  defaultMissionId: string;
  organization: Organization;
  createdAt: string;
  updatedAt: string;
  channels?: Channel[];
};

export type Mission = {
  id: string;
  activityId: string;
  assistantId: string;
  title: string;
  description: string;
  prompt: string;
  status: string;
  // modelLevel: number;
  // modelName: string;
  outboundThreads: OutboundThread[];
  activity: Activity;
  createdAt: string;
  updatedAt: string;
  engagementType: 'inbound' | 'outbound';
  targetAudiences: TargetAudience[];
};

export type OutboundThread = {
  statusOrder: number;
  thread?: {
    id: string;
    lastMessageAt: string;
    sourceUnique: string;
    userInfo: {
      name?: string;
      phoneNumber: string;
      source: string;
    };
  };
  threadMission?: {
    createdAt: string;
    finishedAt: string | null;
    finishedStatus: ThreadFinishedStatus | null;
    finishedStatusReason: string | null;
    missionId: string;
    priority: number;
    status: ThreadStatus;
    statusReason: string | null;
    updatedAt: string | null;
  };
  outboundThread: {
    createdAt: string;
    id: string;
    info: string | null;
    source: string;
    sourceUnique: string | null;
    sourceUniqueInput: string;
    status: OutboundThreadStatus;
    threadId: string | null;
    updatedAt: string | null;
    userInfoName: string | null;
    extras: string | null;
  };
};

export enum OutboundThreadStatus {
  NEW = 'NEW',
  VERIFIED = 'VERIFIED',
  INVALID = 'INVALID',
  CONTACTED = 'CONTACTED',
  ERROR = 'ERROR',
}

export const OutboundThreadStatusLabels = {
  [OutboundThreadStatus.NEW]: 'Novo',
  [OutboundThreadStatus.VERIFIED]: 'Verificado',
  [OutboundThreadStatus.INVALID]: 'Inválido',
  [OutboundThreadStatus.CONTACTED]: 'Contatado',
  [OutboundThreadStatus.ERROR]: 'Erro',
};

export const OutboundThreadStatusColors = {
  [OutboundThreadStatus.NEW]: 'purple',
  [OutboundThreadStatus.VERIFIED]: 'teal',
  [OutboundThreadStatus.INVALID]: 'gray',
  [OutboundThreadStatus.CONTACTED]: 'green',
  [OutboundThreadStatus.ERROR]: 'red',
};

export enum ThreadStatus {
  NEW = 'NEW',
  JUST_STARED = 'JUST_STARED',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  IGNORED = 'IGNORED',
  ERRORED = 'ERRORED',
}

export const ThreadStatusLabels = {
  [ThreadStatus.NEW]: 'A inicar',
  [ThreadStatus.JUST_STARED]: 'Iniciado',
  [ThreadStatus.ONGOING]: 'Em andamento',
  [ThreadStatus.FINISHED]: 'Finalizado',
  [ThreadStatus.IGNORED]: 'Ignorado',
  [ThreadStatus.ERRORED]: 'Erro',
};

export const ThreadStatusColors = {
  [ThreadStatus.NEW]: 'blue',
  [ThreadStatus.JUST_STARED]: 'blue',
  [ThreadStatus.ONGOING]: 'blue',
  [ThreadStatus.FINISHED]: 'green',
  [ThreadStatus.IGNORED]: 'gray',
  [ThreadStatus.ERRORED]: 'red',
};

export enum ThreadFinishedStatus {
  SUCCESSFULLY = 'SUCCESSFULLY',
  PARTIALLY = 'PARTIALLY',
  UNSUCCESSFULLY = 'UNSUCCESSFULLY',
}

export const ThreadFinishedStatusLabels = {
  [ThreadFinishedStatus.SUCCESSFULLY]: 'Sucesso',
  [ThreadFinishedStatus.PARTIALLY]: 'Sucesso parcial',
  [ThreadFinishedStatus.UNSUCCESSFULLY]: 'Sem sucesso',
};

export const ThreadFinishedStatusColors = {
  [ThreadFinishedStatus.SUCCESSFULLY]: 'green',
  [ThreadFinishedStatus.PARTIALLY]: 'lime',
  [ThreadFinishedStatus.UNSUCCESSFULLY]: 'yellow',
};

export type ThreadConversationType =
  | 'personAssistantTest' // Conversation started from the dashboard "Conversar" button
  | 'personAssistant' // Conversation started from public route "/falar/:assistantId" or WhatsApp
  | 'personUser' // Chat between public user and admin, no assistant involved
  | 'personUserTest'; // Chat between 2 admins

export type ThreadMission = {
  missionId: string;
  priority: number;
  status: ThreadStatus;
  statusReason: string | null;
  finishedStatus: ThreadFinishedStatus | null;
  finishedStatusReason: string | null;
  summary: string | null;
  createdAt: string;
  updatedAt: string | null;
  finishedAt: string | null;
};

export type ThreadTag = {
  tagId: string;
  role: string;
  reason?: string;
  createdAt: string;
  deletedAt?: string | null;
  assistantId: string | null;
  userId: string | null;
};

export type Thread = {
  id: string;
  assistantId: string;
  missionId?: string | null; // conferir
  status?: string | null; // conferir tipo
  messages?: Message[];
  createdAt: string;
  updatedAt: string;
  lastMessageAt: string;
  missions: ThreadMission[];
  threadUsers: OrganizationUser[];
  source: 'webAdmin' | 'webUser' | 'whatsapp';
  sourceUnique: string | null;
  conversationType: ThreadConversationType;
  createdByUserId: string | null;
  userAgent: string | null;
  userInfo?: {
    name: string;
    phoneNumber?: string | null;
    email?: string | null;
    source?: string;
    photoUrl?: string;
    whatsappStatus?: string;
    whatsapp?: {
      wuid: string;
      name: string;
      numberExists: boolean;
      picture: string;
      status: string;
      isBusiness: boolean;
    };
  } | null;
  tags: ThreadTag[];
  reduced?: ReducedThread;
  opened?: boolean;
  isReady?: boolean;
  mock?: boolean;
  thoughts?: Thought[];
};

export type FloatingThread = {
  id: string;
  assistantId: string;
  threadId: string;
};

export type ReducedThread = {
  id: string;
  defaultAssistantId: string;
  lastMessageContent?: string;
  lastMessageAt?: string;
  assistantId?: string;
  organizationId?: string;
  userId?: string;
  userInfoName?: string;
  userInfoPhotoUrl?: string;
  source?: string;
  sourceUnique?: string;
  conversationType?: string;
  tags: ThreadTag[];
  mock?: boolean;
};

export type MessageStatus = keyof typeof MessageStatus | null;
export const MessageStatus = {
  received: 'received',
  read: 'read',
  ready: 'ready',
  errored: 'errored',
  typing: 'typing',
  sent: 'sent',
} as const;

export type MessageRole = 'user' | 'assistant' | 'system' | 'function';

export type Message = {
  displayContent?: string | null;
  assistantId: string | null;
  content: string;
  createdAt: string;
  id: string;
  role: MessageRole;
  internalRole: string | null; // Verificar tipo se é string realmente
  channelMessageId: string | null;
  userId?: string | null;
  status: MessageStatus | null;
  toolCallId?: string | null;
  type?: string;
};

export type Thought = {
  messageId: string;
  knowledgeUsed?: KnowledgeUsed[];
  informationAnalysis?: InformationAnalysis;
};

export type ResponseBasis = 'direct' | 'inference' | 'insufficient';

export type InformationAnalysis = {
  confidence: number;
  responseBasis: ResponseBasis;
  missingInformationJustification?: string;
  informationLocationJustification?: string;
};

export type KnowledgeUsed = {
  title: string;
  subjects: string;
  relevanceToAnswer: number;
  justification: string;
};

export type OrganizationUser = {
  id: string;
  threadId: string;
  createdAt: string;
  user: User;
  userId: string;
  role: string; // Verificar ENUM
  reason?: string | null;
  readAt: string | null;
  deletedAt?: string | null;
  escalateReason?: string | null;
  userJustification?: string | null;
};

export type Organization = {
  id: string;
  name: string;
  active: boolean;
  createdAt: string;
};

export type AssistantKnowledge = {
  id: string;
  assistantId: string;
  knowledgeId: string;
  assistant: Assistant;
};

export type Knowledge = {
  id: string;
  title: string;
  content: string;
  subjects: string;
  organizationId?: string;
  assistantKnowledge: AssistantKnowledge[];
};

export type Tag = {
  id: string;
  title: string;
  shortTitle: string;
  description: string;
  createdAt: string;
  organizationId: string;
  threadIds: string[];
};

export type EvolutionV2Config = {
  phoneNumber: string | null;
  instanceId: string;
  instanceName: string;
  instanceApiKey: string;
  status: string;
  qrcode?: string;
  qrcodeCreatedAt?: string;
  qrcodeCount?: number;
  settingRejectCall?: boolean;
  settingRejectCallMessage?: string;
  settingAlwaysOnline?: boolean;
  statusReason: string;
  statusUpdatedAt: string;
};

export type ChannelConfig = {
  evolutionV2: EvolutionV2Config;
  phoneNumber: string | null;
  evolutionInstanceName: string | null;
  evolutionInstanceApiKey: string | null;
  countryCode: string | null;
  stateCode: string | null;
};

export type Channel = {
  id: string;
  assistantId: string | null;
  organizationId: string;
  type: string;
  active: boolean;
  createdAt: string;
  config: ChannelConfig;
  status: string | null;
  connect?: {
    pairingCode: string;
    code: string;
    base64: string;
    count: number;
    qrcodeCreatedAt: string;
  };
};

export type ChannelPost = {
  type: string;
  organizationId: string;
  assistantId: string;
};

export type ChannelPut = {
  organizationId: string;
  type: string;
  config: {
    evolutionV2: {
      settingRejectCall: boolean;
      settingRejectCallMessage: string;
      settingAlwaysOnline: boolean;
    };
  };
};

export type NotificationsPost = {
  userId: string;
  title: string;
  body: string;
  link: string;
};

export type TargetAudience = {
  id: string;
  title: string;
  description: string;
  questions: Question[];
};

export type Question = {
  id: string;
  question: string;
  createdAt: string;
  relevance?: boolean;
};

export type SuggestedQuestion = {
  targetAudience: string;
  targetAudienceId: string;
  questions: Question[];
};

export type SuggestedQuestionResponse = {
  suggestedQuestions: SuggestedQuestion[];
};

export type MissionAddQuestionQuestion = {
  id: string;
  targetAudienceId: string;
  question: string;
  relevance?: boolean;
  answer?: string;
};

export type MissionAddQuestionArgs = {
  missionId: string;
  questions: MissionAddQuestionQuestion[];
};
