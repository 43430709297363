import { ReducedThread, Thread } from '@/typings/backend';

export default function getReducedThreads(threads: Thread[]) {
  return threads.map((thread) => {
    const userId = thread.threadUsers.find((u) => !u.deletedAt)?.userId;
    const lastMessage = thread.messages
      ?.filter(({ role, content, displayContent }) => {
        const message = displayContent || content;
        const isSystem = role === 'system';
        const isDoNotReply = message.includes('do_not_reply');
        const isFunction = role === 'function';
        return !isSystem && !isDoNotReply && !isFunction;
      })
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

    const reduced: ReducedThread = {
      defaultAssistantId: thread.assistantId,
      userId,
      assistantId: userId ? undefined : thread.assistantId,
      id: thread.id,
      tags: thread.tags,
      conversationType: thread.conversationType,
      mock: true,
      organizationId: '',
      source: thread.source,
      sourceUnique: thread.sourceUnique || '',
      lastMessageAt: lastMessage?.createdAt || '',
      lastMessageContent: lastMessage?.content || '',
      userInfoName: thread.userInfo?.name || '',
      userInfoPhotoUrl: thread.userInfo?.photoUrl || '',
    };

    return reduced;
  });
}
