import {
  mockCoreAssistants,
  mockCoreTags,
  mockCoreUsers,
} from '@/views/Storybook/mockCoreWebsocketData';
import React, { useEffect, useState } from 'react';
import { WebsocketContext } from './WebsocketProvider';
import { Assistant, FloatingThread, Tag, Thread } from '@/typings/backend';
import { User } from '@/typings/user';
import { mockChatListScreenshotThreads } from '@/views/Storybook/data/mockChatListScreenshotData';
import {
  mockFunctionMessagesUsers,
  mockFunctionMessagesThread,
} from '@/views/Storybook/data/mockFunctionMessagesData';
import {
  mockAllTypesMessagesUsers,
  mockAllTypesMessagesThread,
} from '@/views/Storybook/data/mockAllTypesMessagesData';
import { mockChatListPersonAssistantThreads } from '@/views/Storybook/data/mockChatListPersonAssistantData';
import { mockChatListPersonUserThreads } from '@/views/Storybook/data/mockChatListPersonUserData';
import getReducedThreads from '@/utils/reduced';

const WebsocketProviderMock = ({ children }: { children: React.ReactNode }) => {
  const [isConnected] = useState(true);

  const [assistants, setAssistants] = useState<Assistant[] | null>([]);
  const [threads, setThreads] = useState<Thread[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const [floatingThreads, setFloatingThreads] = useState<FloatingThread[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [attendantUsers, setAttendantUsers] = useState([] as User[]);
  const [reducedThreads, setReducedThreads] = useState<Partial<Thread>[] | null>(null);

  const addThreadUser = () => {
    console.log('addThreadUser - mock');
  };
  const addThreadTags = () => {
    console.log('addThreadTags - mock');
  };
  const removeThreadUser = () => {
    console.log('removeThreadUser - mock');
  };
  const deleteThreadTag = () => {
    console.log('removeThreadUser - mock');
  };
  const startNewChat = () => {
    console.log('startNewChat - mock');
  };
  const sendMessage = () => {
    console.log('sendMessage - mock');
  };
  const toggleOpenChat = () => {
    console.log('toggleOpenChat - mock');
  };
  const closeFloatingThread = () => {
    console.log('closeFloatingThread - mock');
  };
  const cleanSocket = () => {
    console.log('cleanSocket - mock');
  };
  const deletePublicThread = () => {
    console.log('deletePublicThread - mock');
  };
  const missionAddQuestion = () => {
    console.log('missionAddQuestion - mock');
  };

  useEffect(() => {
    // The initial value is [] and some components are listening to the websocket
    // And expecting a change in the state to recalculate the height of the chat items
    const timer = setTimeout(() => {
      const filteredUsers = mockCoreUsers.filter(
        (user) => user.attendantAreas && user.attendantAreas.length > 1,
      );

      const threads = [
        ...mockChatListScreenshotThreads,
        mockFunctionMessagesThread,
        mockAllTypesMessagesThread,
        ...mockChatListPersonAssistantThreads,
        ...mockChatListPersonUserThreads,
      ];

      const reducedThreads = getReducedThreads(threads);

      const threadsWithReduced = threads.map((thread) => ({
        ...thread,
        reduced: reducedThreads.find((r) => r.id === thread.id),
      }));

      setAssistants(mockCoreAssistants);
      setReducedThreads(threadsWithReduced);
      setThreads(threads);
      setTags(mockCoreTags);
      setFloatingThreads([]);
      setUsers([...mockCoreUsers, ...mockFunctionMessagesUsers, ...mockAllTypesMessagesUsers]);
      setAttendantUsers(filteredUsers);
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <WebsocketContext.Provider
      value={{
        isConnected,
        assistants,
        threads,
        tags,
        floatingThreads,
        loadingThreads: isLoading,
        users,
        attendantUsers,
        addThreadUser,
        addThreadTags,
        removeThreadUser,
        deleteThreadTag,
        startNewChat,
        sendMessage,
        toggleOpenChat,
        closeFloatingThread,
        cleanSocket,
        setFloatingThreads,
        setAssistants,
        deletePublicThread,
        missionAddQuestion,
        joinChannel: () => () => void 0, // Real functions has a callback on the return
        isReady: true,
        reducedThreads,
        setReducedThreads,
        getMoreReducedThreads: () => void 0,
        getThreadsByIds: () => void 0,
        hasMoreReducedThreads: false,
        loadingReducedThreads: false,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketProviderMock;
