type AnyObject = { [key: string]: any }; // eslint-disable-line @typescript-eslint/no-explicit-any

const ROOT_KEY = '$ROOT_KEY';

function mergeObjects<T extends AnyObject>(
  target: T,
  source: T,
  keyField = 'id',
  keyFieldOverrides: Record<string, string> = {},
): T {
  // Cria uma referência para o objeto de saída
  const output: T = target;

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // Se ambos forem arrays, faz o merge baseado no campo 'id'
      (output as AnyObject)[key] = internalMergeArraysById(
        targetValue,
        sourceValue,
        keyField,
        key,
        keyFieldOverrides,
      );
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      // Se ambos forem objetos, faz o deep merge recursivo
      (output as AnyObject)[key] = mergeObjects(
        targetValue as AnyObject,
        sourceValue as AnyObject,
        keyField,
        keyFieldOverrides,
      );
    } else {
      // Caso contrário, substitui o valor do target pelo valor do source
      (output as AnyObject)[key] = sourceValue;
    }
  });

  return output;
}

function internalMergeArraysById<T extends AnyObject>(
  targetArray: T[],
  sourceArray: T[],
  keyField: string,
  fieldName: string,
  keyFieldOverrides: Record<string, string> = {},
): T[] {
  const overwrittenKeyField = keyFieldOverrides[fieldName] || keyField;
  const mergedArray = [...targetArray];
  const targetMap = new Map(mergedArray.map((item) => [item[overwrittenKeyField], item]));

  sourceArray.forEach((sourceItem) => {
    const sourceId = sourceItem[overwrittenKeyField];
    if (sourceId !== undefined) {
      if (targetMap.has(sourceId)) {
        let targetObject = targetMap.get(sourceId) || ({} as T);
        // Se for a raiz do merge, cria um novo objeto para garantir que a referência seja diferente
        if (fieldName === ROOT_KEY) {
          targetObject = Object.assign({}, targetObject);
        }
        // Faz o deep merge se o item com o mesmo id for encontrado
        targetMap.set(
          sourceId,
          mergeObjects(targetObject, sourceItem, keyField, keyFieldOverrides),
        );
      } else {
        // Adiciona o item se o id não for encontrado
        targetMap.set(sourceId, sourceItem);
      }
    } else {
      // Se não houver id, apenas concatena o item
      mergedArray.push(sourceItem);
    }
  });

  // Retorna o array mesclado convertendo o map de volta em array
  return Array.from(targetMap.values());
}

function isObject(obj: unknown): obj is AnyObject {
  return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}

export function mergeArraysById<T extends AnyObject>(
  targetArray: T[],
  sourceArray: T[],
  keyField: string,
  keyFieldOverrides: Record<string, string> = {},
): T[] {
  return internalMergeArraysById(targetArray, sourceArray, keyField, ROOT_KEY, keyFieldOverrides);
}
