import { Message, OrganizationUser, Thread, ThreadMission, ThreadStatus } from '@/typings/backend';
import {
  mockCoreAssistants,
  mockCoreOrganizationUsers,
  mockCoreTags,
} from '../mockCoreWebsocketData';
import { User } from '@/typings/user';

const missions: ThreadMission[] = [
  {
    missionId: '6687d91ee33a0763a7ba2175',
    priority: 0,
    status: ThreadStatus.ONGOING,
    statusReason:
      'O usuário está buscando um orçamento para a locação de um auditório em novembro.',
    finishedStatus: null,
    finishedStatusReason: null,
    summary:
      'O usuário solicitou um orçamento para a locação do auditório do Moinho nos dias 27 e 28 de novembro.',
    createdAt: '2024-09-11T20:56:52.338Z',
    updatedAt: '2024-09-23T19:55:50.833Z',
    finishedAt: null,
  },
];

const messages: Message[] = [
  {
    role: 'assistant',
    internalRole: 'assistant',
    content: 'Boa tarde, Marluce! Para que dia você gostaria de reservar a sala?',
    id: '3EB0E286172C79FEBC86D1',
    channelMessageId: '3EB0E286172C79FEBC86D1',
    createdAt: '2024-09-23T19:52:52.000Z',
    assistantId: null,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'user',
    internalRole: 'user',
    content: 'Eu já fechei a sala para essa rdg, mas gostaria de um orçamento para novembro',
    id: '3ACAD6CEB37733BE18F3',
    channelMessageId: '3ACAD6CEB37733BE18F3',
    createdAt: '2024-09-23T19:53:06.000Z',
    assistantId: null,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'assistant',
    internalRole: 'assistant',
    content:
      'Claro, posso buscar um orçamento para você. Para qual dia em novembro você precisa da sala?',
    id: '3EB09F964F7CC7D4DAF9F7',
    channelMessageId: '3EB09F964F7CC7D4DAF9F7',
    createdAt: '2024-09-23T19:53:14.000Z',
    assistantId: null,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'user',
    internalRole: 'user',
    content: 'Seria o auditório, para 116 pessoas',
    id: '3A5E627DD8258BB68B1A',
    channelMessageId: '3A5E627DD8258BB68B1A',
    createdAt: '2024-09-23T19:53:17.000Z',
    assistantId: null,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'assistant',
    internalRole: 'assistant',
    content:
      'Entendi, Marluce! Vou buscar o orçamento para o auditório com capacidade para 116 pessoas em novembro. Você tem uma data específica em mente?',
    id: '3EB0E0C68C2443BD4FAD25',
    channelMessageId: '3EB0E0C68C2443BD4FAD25',
    createdAt: '2024-09-23T19:53:25.000Z',
    assistantId: null,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'user',
    internalRole: null,
    content: 'Dia 27 e 28',
    id: '3AEEDA53629E971B1CA1',
    channelMessageId: null,
    createdAt: '2024-09-23T19:53:31.672Z',
    assistantId: mockCoreAssistants[0].id,
    userId: null,
    status: 'received',
    toolCallId: null,
  },
  {
    role: 'function',
    internalRole: null,
    content:
      '{"name":"updateUserInfo","args":{"name":"Marluce"},"result":"Dados atualizados com sucesso! UserInfo atual: \\n          {\\"name\\":\\"Marluce\\",\\"phoneNumber\\":\\"553291518514@s.whatsapp.net\\",\\"source\\":\\"Whatsapp\\"}","toolCallId":"call_9xLMOa1GT6CmI5kA6UqvQktP"}',
    id: '66f1c740e499e22b128676df',
    channelMessageId: null,
    createdAt: '2024-09-23T19:53:36.147Z',
    assistantId: null,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'function',
    internalRole: null,
    content:
      '{"name":"escalateToHumanAgent","args":{"reason":"Buscar orçamento para o auditório nos dias 27 e 28 de novembro."},"result":"Mariana Rodrigues acaba de ser adicionada na conversa. Se despeça então do usuário educadamente e diga que Mariana Rodrigues dará continuídade na conversa a partir de agora.","toolCallId":"call_c1ZbANSDENYw41BhWVpuJevp"}',
    id: '66f1c742e499e22b128676e0',
    channelMessageId: null,
    createdAt: '2024-09-23T19:53:38.102Z',
    assistantId: null,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'assistant',
    internalRole: 'assistant',
    content:
      'Vou encaminhar sua solicitação para a Mariana, que dará continuidade ao atendimento. Agradeço pela sua paciência, Marluce! \n\nMariana, por favor, siga com as informações sobre o orçamento para o auditório nos dias 27 e 28 de novembro.',
    id: '3EB052A2CFAA5F6107FFC2',
    channelMessageId: null,
    createdAt: '2024-09-23T19:53:39.000Z',
    assistantId: mockCoreAssistants[0].id,
    userId: null,
    status: null,
    toolCallId: null,
  },
  {
    role: 'assistant',
    internalRole: 'assistant',
    content: 'Tudo joia!? Vou olhar aqui se a data está disponível, só um instante.',
    id: '3EB052A2CFAA5F6107FFC2',
    channelMessageId: null,
    createdAt: '2024-09-23T20:05:39.000Z',
    assistantId: null,
    userId: '668fdfae5eefdd65b0f87467',
    status: null,
    toolCallId: null,
  },
];

export const mockFunctionMessagesOrganizationUsers: OrganizationUser[] = [
  {
    id: '66f1c51569353072f5f16812',
    threadId: '66e20414f622587755cf86dc',
    userId: '668fe1055eefdd65b0f8746a',
    role: 'PARTICIPANT',
    escalateReason: null,
    userJustification: null,
    readAt: null,
    createdAt: '2024-09-23T19:44:21.256Z',
    deletedAt: '2024-09-23T20:20:06.979Z',
    user: {
      id: '668fe1055eefdd65b0f8746a',
      email: 'anacarolina.batista@nossomoinho.com',
      password: '$2a$10$fJzp9tzgUN3Yg0I1nVn6Aurp/o.ie/Aev0CVCVULIj1C3XJzaP5ea',
      phoneNumber: '5532999067393',
      name: 'Ana Carolina',
      shortName: 'Carol',
      superRole: null,
      notificationTokens: [
        'dKD-tM9ASLO0dCHcHPL-6v:APA91bEDfioC6-lCX5hzhaTLN3SUWgT-dsSdzUKdQvwWEQ9lYLbJ_HyZ9sWXvBhhr_WwftzJ9uhRv_Qh75EgEpF6FgDHIz1G3yYOP_3aSoLOaAwcO8nIi7I0hde-OqDE25RoHiSy4yGs',
        'ePxHUYW36rp_XdyUBI5YwU:APA91bEqvKcmKA9pPA03dcxZeO4m4shmpx8zonPxnGO64SyG5l8fbYTQlfJHC23s06HB2ptFj8rINYwE9dh00nZiX1rrGJrqdi3Y6aXoJ31jgI70W_lAQNVQlP_pnmoPy9o-eLTasg9C',
        'cErlbClbd79B2YDtKWf55l:APA91bFNMwMw2j4DaeuuD3wz-elFDLP2upoe6rnhGMV6yLLNEISxW7xOby0Tv1oOuV6n54H6x4XoI7cX-s7poV2oHKNK1BjWzRVQ3d_ftNlxXlhTUWJ57UX_FQz0XChycR06QZi8w_6N',
      ],
      attendantAreas:
        'Hub de inovação; locação de espaços para trabalhar; Salas Privativas; escritórios privativos',
      knowledgeAreas: 'Hub de Inovação',
      createdAt: '2024-07-11T13:41:25.743Z',
    },
  },
  {
    id: '66f1c74169353072f5f16813',
    threadId: '66e20414f622587755cf86dc',
    userId: '668fdfae5eefdd65b0f87467',
    role: 'PARTICIPANT',
    escalateReason: 'Buscar orçamento para o auditório nos dias 27 e 28 de novembro.',
    userJustification:
      'Ela atende diretamente a área de contratação do auditório e pode fornecer as informações necessárias sobre a locação para o evento.',
    readAt: null,
    createdAt: '2024-09-23T19:53:37.407Z',
    deletedAt: null,
    user: {
      id: '668fdfae5eefdd65b0f87467',
      email: 'mariana.rodrigues@nossomoinho.com',
      password: '$2a$10$rvt4tp2P824UHnFJfvNdqOyapuPo3Zt4AIEQrxBUJuvC1/MUzZ5D.',
      phoneNumber: '+5532984137592',
      name: 'Mariana Rodrigues',
      shortName: 'Mariana',
      superRole: null,
      notificationTokens: [
        'fWEDsXnyrZ_50D8dpFmWSL:APA91bHOa3aGyi3weLUIJfZascBOPwoQfbQ79YCP49S2uFFkL_9SULBd94YF5bXUSz9A13DbnMx6CHlrc40wfn0RCpjaYcjzQZDLT1D41MC46Bh2lLp_sVxGnUGqL-oIyc6fWpKwGTBt',
      ],
      attendantAreas:
        'Contratação do auditório, salas multiuso e espaços para eventos e treinamentos; sala para 30 pessoas; espaço temporário',
      knowledgeAreas: '',
      createdAt: '2024-07-11T13:35:42.972Z',
    },
  },
];

export const mockFunctionMessagesUsers: User[] = mockCoreOrganizationUsers.map(
  (organizationUser) => organizationUser.user,
);

export const mockFunctionMessagesThread: Thread = {
  missions,
  messages,
  id: 'thread-with-function-messages',
  assistantId: mockCoreAssistants[0].id,
  missionId: missions[0].missionId,
  source: 'whatsapp',
  sourceUnique: '553299999@s.whatsapp.net',
  conversationType: 'personUser',
  userInfo: {
    name: 'Tiago Gouvêa',
    phoneNumber: '553288735683@s.whatsapp.net',
    source: 'Whatsapp',
    photoUrl:
      'https://pps.whatsapp.net/v/t61.24694-24/429511029_784153307000571_4072770123066820445_n.jpg?ccb=11-4&oh=01_Q5AaIO0NFC53lweQwIO30t9jMfFpxqYx8rqFgrDkHf_8aiBc&oe=670E5569&_nc_sid=5e03e0&_nc_cat=106',
    whatsappStatus: 'Desenvolvendo...',
    whatsapp: {
      wuid: '553288735683@s.whatsapp.net',
      name: 'Tiago Gouvêa',
      numberExists: true,
      picture:
        'https://pps.whatsapp.net/v/t61.24694-24/429511029_784153307000571_4072770123066820445_n.jpg?ccb=11-4&oh=01_Q5AaIO0NFC53lweQwIO30t9jMfFpxqYx8rqFgrDkHf_8aiBc&oe=670E5569&_nc_sid=5e03e0&_nc_cat=106',
      status: 'Desenvolvendo...',
      isBusiness: false,
    },
  },
  createdByUserId: null,
  userAgent: null,
  createdAt: '2024-09-11T20:56:52.338Z',
  updatedAt: '2024-09-23T19:55:50.833Z',
  lastMessageAt: '2024-09-23T19:53:39.000Z',
  tags: mockCoreTags.map((tag) => ({
    ...tag,
    role: 'assistant',
    tagId: tag.id,
    assistantId: mockCoreAssistants[0].id,
    userId: null,
  })),
  threadUsers: mockFunctionMessagesOrganizationUsers,
};
