import { Thread } from '@/typings/backend';
import { THREADS_READ_STATUS } from './storageKeys';
import dayjs from 'dayjs';

export type ThreadReadStatus = Record<string, { visitedAt: string }>;

export const getThreadsReadStatus = () => {
  const threadsReadStatus = JSON.parse(
    localStorage.getItem(THREADS_READ_STATUS) || '{}',
  ) as ThreadReadStatus;

  return threadsReadStatus;
};

export const setThreadsReadStatus = (threadsReadStatus: ThreadReadStatus) => {
  localStorage.setItem(THREADS_READ_STATUS, JSON.stringify(threadsReadStatus));
};

export const updateThreadStatus = (threadId: string) => {
  const threads = getThreadsReadStatus();

  const now = new Date();
  const visitedAt = now.toISOString();

  threads[threadId] = {
    visitedAt,
  };

  setThreadsReadStatus(threads);
};

export const getUnreadMessages = (threadId: string, messages?: Thread['messages']) => {
  const threads = getThreadsReadStatus();

  if (!messages || messages.length === 0) {
    return 0;
  }

  if (!threads || !threads[threadId]) {
    return 0;
  }

  const visitedAt = threads[threadId]?.visitedAt;
  if (!visitedAt) {
    return 0;
  }

  const threadVisitedAt = dayjs(threads[threadId].visitedAt);

  const unreadMessages = messages.filter((message) => {
    const messageCreatedAt = dayjs(message.createdAt);

    return messageCreatedAt.isAfter(threadVisitedAt);
  });

  return unreadMessages?.length || 0;
};
