import { useStore } from '@/stores/store';
import { Thread } from '@/typings/backend';
import { getThreadsReadStatus, setThreadsReadStatus } from '@/utils/threadReadStatus';
import { useEffect } from 'react';

export function useThreadUsers(threads: Thread[]) {
  const user = useStore((store) => store.user);

  useEffect(() => {
    if (user && threads?.length > 0) {
      const userThreads = threads.filter(
        (t) =>
          t.createdByUserId === user.id || !!t.threadUsers?.find((tu) => tu.userId === user.id),
      );

      const threadsReadStatus = getThreadsReadStatus();

      // Use old date as default so all the threads created before this feature will have their messages set to unread
      const defaultDate = new Date('1900-01-01T00:00:00Z').toISOString();

      userThreads.forEach((thread) => {
        if (!threadsReadStatus[thread.id]) {
          threadsReadStatus[thread.id] = {
            visitedAt: defaultDate,
          };
        }
      });

      setThreadsReadStatus(threadsReadStatus);
    }
  }, [threads, user]);
}
