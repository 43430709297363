'use client';

import React, { PropsWithChildren } from 'react';

import ObjectId from 'bson-objectid';

import WebsocketProviderReal from './WebsocketProviderReal';
import WebsocketProviderMock from './WebsocketProviderMock';
import { User } from '@/typings/user';
import {
  Assistant,
  FloatingThread,
  MissionAddQuestionArgs,
  Tag,
  Thread,
  ThreadUserArgs,
  ThreadTagsArgs,
} from '@/typings/backend';

export type GetMoreReducedThreadsArgs = {
  tagIds?: string[];
  assistantIds?: string[];
  userIds?: string[];
  lastMessageBefore?: string;
};

export type WebsocketContextType = {
  isConnected: boolean;
  assistants: Assistant[] | null;
  threads: Thread[] | null;
  reducedThreads: Partial<Thread>[] | null;
  hasMoreReducedThreads: boolean;
  loadingReducedThreads: boolean;
  getMoreReducedThreads: (args: GetMoreReducedThreadsArgs) => void;
  getThreadsByIds: (ids: string[]) => void;
  tags: Tag[] | null;
  users: User[] | null;
  floatingThreads: FloatingThread[];
  loadingThreads: boolean;
  attendantUsers: User[];
  setAssistants: React.Dispatch<React.SetStateAction<Assistant[] | null>>;
  addThreadUser: (data: ThreadUserArgs, onError?: () => void) => void;
  addThreadTags: (data: ThreadTagsArgs, onError?: () => void) => void;
  removeThreadUser: (data: ThreadUserArgs, onError?: () => void) => void;
  deleteThreadTag: (data: ThreadTagsArgs, onError?: () => void) => void;
  startNewChat: (data: {
    assistantId: string;
    makeChatFloating?: boolean;
    onSuccess?: (thread: Thread) => void;
  }) => void;
  sendMessage: (data: {
    message: string;
    thread?: Thread;
    assistant: Assistant;
    floatingThreadId?: string;
  }) => void;
  toggleOpenChat: (threadId: string, isOpen?: boolean) => void;
  closeFloatingThread: (id?: string) => void;
  cleanSocket: () => void;
  setFloatingThreads: (threads: FloatingThread[]) => void;
  deletePublicThread: (threadId: string) => void;
  joinChannel: <T>(
    channelName: string,
    callback: (data: T) => void,
    callbackOnJoin: boolean,
    listen: boolean,
    listenerName: string,
    afterJoin?: (data: T) => void,
  ) => () => void;
  missionAddQuestion: (data: MissionAddQuestionArgs, onError?: () => void) => void;
  isReady: boolean;
  setReducedThreads: React.Dispatch<React.SetStateAction<Partial<Thread>[] | null>>;
};

export const createId = () => new ObjectId().toHexString();

export const WebsocketContext = React.createContext({} as WebsocketContextType);

const WebsocketProvider: React.FC<
  PropsWithChildren<{ isPublic?: boolean; isTest?: boolean; isStoryBook?: boolean }>
> = ({ children, isPublic = false, isStoryBook = false, isTest }) => {
  if (isStoryBook) {
    return <WebsocketProviderMock>{children}</WebsocketProviderMock>;
  }

  return (
    <WebsocketProviderReal isPublic={isPublic} isTest={isTest}>
      {children}
    </WebsocketProviderReal>
  );
};

export const useWebsocket = () => React.useContext(WebsocketContext);

export default WebsocketProvider;
