import { Message, OrganizationUser, Thread } from '@/typings/backend';
import {
  mockCoreAssistants,
  mockCoreOrganizationUsers,
  mockCoreTags,
} from '../mockCoreWebsocketData';

const messages: Partial<Message>[] = [
  {
    assistantId: mockCoreAssistants[0].id,
    content: 'Gostaria de saber mais sobre como funciona a consultoria jurídica para empresas',
    createdAt: formatLastMessageAt('8:25:22'),
    id: 'message-1',
    role: 'user',
    status: 'sent',
  },
  {
    id: '3EB0CD32101FBB043FC479',
    content:
      'Bom dia Ruy! Como vai? \n\nOferecemos consultoria jurídica completa para empresas, incluindo a elaboração e revisão de contratos, assessoria em questões tributárias, compliance, além de suporte em processos de licitação. Poderia me dizer um mais sobre seu ramo de atuação?',
    role: 'assistant',
    createdAt: formatLastMessageAt('8:26:00'),
    status: 'read',
  },
  {
    id: '4A5B6C7D8E9F0A1B2C3D4E5F',
    content: 'Tenho uma pequena empresa de serviços e preciso de contratos trabalhistas.',
    role: 'user',
    createdAt: formatLastMessageAt('8:26:15'),
    status: 'read',
  },
  {
    id: '5F6E7D8C9B0A1B2C3D4E5F6G',
    content:
      'Perfeito! Trabalhamos frequentemente com contratos trabalhistas, desde a elaboração até a revisão, garantindo que estejam em conformidade com a legislação atual.\n\nQual dia e horário seria mais conveniente para você, para agendarmos uma reunião com um de nossos advogados especializados?',
    role: 'assistant',
    createdAt: formatLastMessageAt('8:26:40'),
    assistantId: mockCoreAssistants[1].id,
  },
  {
    assistantId: mockCoreAssistants[1].id,
    content:
      '{"mediaUrl":"https://assistant-prod.s3.amazonaws.com/evolution-api/10a605f9-9de8-42d6-9ec4-1825d8c765c1/553288735683%40s.whatsapp.net/audioMessage/629D8596B04054D3315FB3BAF3BCA1F1.m4a?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASPZFZNDCAKMRRX4J%2F20241004%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241004T133604Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=99b9a3576941b165a509a9e51c0b1fa0aec76ddcf2f037866f4c0eec7c1fd78f","transcription":"[audio_to_text] Hoje as 13:30 é bom pra mim"}',
    createdAt: formatLastMessageAt('8:27:02'),
    type: 'audioMessage',
    role: 'user',
  },
  {
    role: 'assistant',
    internalRole: null,
    content:
      'Ótimo! A reunião foi agendada com o Dr. Sergio Lefair, nosso especialista em contratos trabalhistas e empresariais. Você receberá um e-mail de confirmação com o link de acesso. Posso te ajudar com mais alguma coisa por agora?',
    id: '66b51f773351dc21e9a601de',
    createdAt: formatLastMessageAt('8:27:20'),
    status: 'received',
  },
  {
    role: 'user',
    internalRole: 'user',
    content: 'Só isso mesmo, obrigado!',
    id: '3AD1192A9B95F24C7D2A',
    createdAt: formatLastMessageAt('8:28:33'),
  },
];

function mockMessagesLastMessage(lastMessage: string, lastMessageHour: string) {
  return [
    {
      role: 'user',
      internalRole: 'user',
      content: lastMessage,
      id: '3AD1192A9B95F24C7D2A',
      createdAt: formatLastMessageAt(lastMessageHour),
    },
  ];
}

function formatLastMessageAt(time: string) {
  const timeParts = time.split(':').map(Number);
  const now = new Date();
  const [hours, minutes, seconds] = timeParts;
  now.setHours(hours, minutes, seconds ?? 0, 0);
  return now.toISOString();
}

const mockTreadFactory = (
  source: string,
  userName: string,
  lastMessage: string | null,
  lastMessageHour: string,
  image: string,
  threadUser?: OrganizationUser | null,
  tagId?: string,
) => {
  const user: Thread['userInfo'] = {
    name: userName,
    phoneNumber: null,
    email: null,
    photoUrl: image.length > 5 ? image : 'https://i.pravatar.cc/150?img=' + image,
    whatsapp: {
      wuid: '',
      name: '',
      numberExists: false,
      picture: image.length > 5 ? image : 'https://i.pravatar.cc/150?img=' + image,
      status: '',
      isBusiness: false,
    },
  };

  return structuredClone({
    missions: [],
    messages: lastMessage ? mockMessagesLastMessage(lastMessage, lastMessageHour) : messages,
    id: `mockChatScreenShot-thread-${lastMessageHour}`,
    assistantId: mockCoreAssistants[0].id,
    source,
    sourceUnique: null,
    conversationType: 'personUser',
    userInfo: user,
    createdByUserId: '',
    lastMessageAt: formatLastMessageAt(lastMessageHour),
    tags: tagId
      ? [
          {
            tagId: tagId,
            role: 'user',
            reason: '',
            createdAt: '',
            deletedAt: null,
            assistantId: '',
            userId: '',
          },
        ]
      : [],
    opened: true,
    mock: true,
    threadUsers: threadUser ? [threadUser] : [],
  }) as Partial<Thread>;
};

export const mockChatListScreenshotThreads = [
  mockTreadFactory(
    'whatsapp',
    'Laura Gouvêa',
    'Obrigada pelas informações!',
    '8:37',
    '16',
    null,
    mockCoreTags[0].id,
  ),
  mockTreadFactory(
    'whatsapp',
    'Bruno Iyan',
    'O email será enviado hoje ainda, no começo da tarde',
    '8:36',
    '12',
    null,
    mockCoreTags[2].id,
  ),
  mockTreadFactory('whatsapp', 'Ruy Marcatto', null, '8:29', '11', null, mockCoreTags[0].id),
  mockTreadFactory(
    'web',
    'Wendel Oliveira',
    'É disso que eu precisava mesmo, vai facilitar muito!',
    '8:23',
    'https://pps.whatsapp.net/v/t61.24694-24/427472743_971751700953383_1649213556044644210_n.jpg?ccb=11-4&oh=01_Q5AaIPf4DVM6OQCvNrfFTg9X4y3qWtD5xoOTLUliuaqc0LWC&oe=67126F9E&_nc_sid=5e03e0&_nc_cat=101',
    null,
    mockCoreTags[0].id,
  ),
  mockTreadFactory(
    'whatsapp',
    'Vinicius Reinato',
    'Concordo. Obrigado pela atenção João.',
    '8:16',
    '3',
    mockCoreOrganizationUsers[1],
    mockCoreTags[4].id,
  ),
  mockTreadFactory(
    'whatsapp',
    'Derick Costa',
    'Obrigado Camila. Entendi como funciona sim.',
    '8:03',
    '8',
  ),
  mockTreadFactory(
    'whatsapp',
    'Karina Serestchi',
    'Muito bom farei isso mesmo porque preciso ir',
    '7:55',
    'https://pps.whatsapp.net/v/t61.24694-24/428017170_1235625327404258_3616687586040223106_n.jpg?ccb=11-4&oh=01_Q5AaIO5EyNzwXK75_7e5qcjE0vzZj39-M9uFtM2ojv0asT2X&oe=67209BC9&_nc_sid=5e03e0&_nc_cat=102',
    null,
    mockCoreTags[2].id,
  ),
  mockTreadFactory('web', 'Franciane Camili', 'Irei pela tarde ai então, obrigada!', '7:46', '9'),
  mockTreadFactory(
    'whatsapp',
    'Tiago Gouvêa',
    'Que bom que gostou, essa é a melhor solução mesmo',
    '7:30',
    'https://pps.whatsapp.net/v/t61.24694-24/429511029_784153307000571_4072770123066820445_n.jpg?ccb=11-4&oh=01_Q5AaIFwxAqvs9XkJ_8Y0YCnngXfyn01tlk9rHQF7Gcaqfj64&oe=6720CA69&_nc_sid=5e03e0&_nc_cat=106',
    null,
    mockCoreTags[0].id,
  ),
  mockTreadFactory(
    'whatsapp',
    'Alberto Menezes',
    'tudo bem até mais',
    '6:59',
    '4',
    mockCoreOrganizationUsers[1],
    mockCoreTags[1].id,
  ),
  mockTreadFactory(
    'whatsapp',
    'Lais Almeida',
    'Te aguardamos aqui! Tenha um bom dia!',
    '6:23',
    '10',
  ),
  mockTreadFactory(
    'web',
    'Barabara Fiuza',
    'Entendi sim, brigadu',
    '5:34',
    'https://pps.whatsapp.net/v/t61.24694-24/425290469_3884893538459726_4569523036935860022_n.jpg?ccb=11-4&oh=01_Q5AaIC1Gm4patxvOsnVOMoqOiX4k9CblM7xTuQ3SnCBzmQVM&oe=6717BCD5&_nc_sid=5e03e0&_nc_cat=109',
    null,
    mockCoreTags[1].id,
  ),
] as Thread[];
