import { Thread, ThreadStatus } from '@/typings/backend';
import { mockCoreAssistants, mockCoreOrganizationUsers } from '../mockCoreWebsocketData';

// Usuário identificado (com userInfo) conversando com um Usuário, segundo caso issue segunda parte
export const personUserTalkingUser: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 1,
      status: ThreadStatus.FINISHED,
      statusReason: 'O usuário ainda está aguardando uma resposta sobre o seu processo.',
      finishedStatus: null,
      finishedStatusReason: null,
      summary:
        'O usuário solicitou informações sobre seu processo, mas ainda não forneceu os dados necessários para prosseguir.',
      createdAt: '2024-09-15T18:49:58.437Z',
      updatedAt: '2024-09-15T18:51:34.676Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content: 'Olá Dra. Larissa. Quero saber sobre meu processo',
      id: '66e72c5657b4ced1e6f14e4b',
      channelMessageId: null,
      createdAt: '2024-09-15T18:49:59.495Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Sra., sou a secretária jurídica da Dra. Larissa Rodrigues. Para que eu possa ajudá-la com informações sobre o seu processo, poderia informar seu nome completo e CPF? Assim, poderei direcionar sua solicitação adequadamente. Como a Sra. está?',
      id: '66e72c5989b9c3222e34c1f4',
      channelMessageId: null,
      createdAt: '2024-09-15T18:50:01.544Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'laura 1241241412',
      id: '66e72d5a57b4ced1e6f14e4c',
      channelMessageId: null,
      createdAt: '2024-09-15T18:54:19.036Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Sra. Laura, informo que outra pessoa dará continuidade à sua solicitação sobre o processo. Agradeço pela sua compreensão.',
      id: '66e72d6189b9c3222e34c1f6',
      channelMessageId: null,
      createdAt: '2024-09-15T18:54:25.074Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Estou bem, obrigada ',
      id: '66e72d6157b4ced1e6f14e4d',
      channelMessageId: null,
      createdAt: '2024-09-15T18:54:26.760Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: 'received',
    },
  ],
  id: '66e72c5557b4ced1e6f14e4a',
  assistantId: mockCoreAssistants[0].id,
  source: 'webUser',
  sourceUnique: null,
  conversationType: 'personUserTest',
  status: null,
  userInfo: {
    name: 'Laura',
    phoneNumber: null,
    email: null,
  },
  createdByUserId: null,
  userAgent:
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36 Norton/127.0.0.0',
  createdAt: '2024-09-15T18:49:58.437Z',
  updatedAt: '2024-09-15T18:55:58.168Z',
  lastMessageAt: '2024-09-15T18:54:26.760Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [mockCoreOrganizationUsers[1]],
};

// Criada pelo usuário do admin (com `createdByUserId`) com usuário anonimo, conversando com um Usuário (outro) `personUser + !userInfo + webAdmin`, terceiro caso issue segunda parte
export const personUserAnonymousAdminUser: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 0,
      status: ThreadStatus.JUST_STARED,
      statusReason: null,
      finishedStatus: null,
      finishedStatusReason: null,
      summary: null,
      createdAt: '2024-09-02T14:26:10.703Z',
      updatedAt: '2024-09-02T14:26:34.854Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content: 'Bom dia, é uma emergencia.',
      id: '66d5cb03274bbd6706645a42',
      channelMessageId: null,
      createdAt: '2024-09-02T14:26:12.370Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Bom dia! Para questões emergenciais, recomendo que entre em contato diretamente com o escritório da Dra. Larissa pelos telefones 32-99178-0716 ou 32-98889-0716. Eles poderão oferecer a assistência necessária.',
      id: '66d5cb053f09ab589a7f25c7',
      channelMessageId: null,
      createdAt: '2024-09-02T14:26:13.452Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Pode por favor transferir pra ela?',
      id: '66d5cb15274bbd6706645a43',
      channelMessageId: null,
      createdAt: '2024-09-02T14:26:30.278Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Outra pessoa foi adicionada à conversa e dará continuidade no atendimento agora. Agradeço pela compreensão!',
      id: '66d5cb1a3f09ab589a7f25c9',
      channelMessageId: null,
      createdAt: '2024-09-02T14:26:34.854Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
  ],
  id: '66d5cb02274bbd6706645a41',
  assistantId: mockCoreAssistants[0].id,
  missionId: '66d5c542291f53c6e43b0384',
  source: 'webAdmin',
  sourceUnique: null,
  conversationType: 'personUserTest',
  status: 'QUEUED',
  userInfo: null,
  createdByUserId: '66d1a47d8e4a065d4e901096',
  userAgent:
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36',
  createdAt: '2024-09-02T14:26:10.703Z',
  updatedAt: '2024-09-02T14:26:35.328Z',
  lastMessageAt: '2024-09-02T14:26:34.854Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [mockCoreOrganizationUsers[0]],
};

// Criada pelo usuário do admin (com createdByUserId) com usuário identificado (userInfo), conversando o Usuário (outro), quarto caso issue segunda parte
export const personUserMockUserInfoAdminUser: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 0,
      status: ThreadStatus.ONGOING,
      statusReason:
        'O usuário está aguardando o atendimento da Dra. Larissa para discutir a possível causa.',
      finishedStatus: null,
      finishedStatusReason: null,
      summary:
        'O usuário, Sra. Tiago Gouvêa, expressou interesse em discutir uma possível causa com a Dra. Larissa e forneceu informações necessárias para o encaminhamento.',
      createdAt: '2024-09-15T18:31:43.902Z',
      updatedAt: '2024-09-15T18:39:25.595Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content: 'Boa tarde',
      id: '66e72810557f78a14fbf0d75',
      channelMessageId: null,
      createdAt: '2024-09-15T18:31:44.281Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'errored',
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Como vai?',
      id: '66e72884557f78a14fbf0d76',
      channelMessageId: null,
      createdAt: '2024-09-15T18:33:40.152Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'errored',
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Tudo bem?',
      id: '66e72905557f78a14fbf0d77',
      channelMessageId: null,
      createdAt: '2024-09-15T18:35:50.116Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Boa tarde, Sra. \n\nEstou à disposição como secretária jurídica da Dra. Larissa Rodrigues. Para que eu possa direcionar o atendimento de forma mais eficiente, poderia me informar seu nome e o motivo do contato? Além disso, esta situação é uma emergência?',
      id: '66e7290701c2fd382315cef6',
      channelMessageId: null,
      createdAt: '2024-09-15T18:35:51.776Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Gostaria de falar com a Larissa.',
      id: '66e7295c557f78a14fbf0d78',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:16.646Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Sra., para que eu possa encaminhar seu pedido à Dra. Larissa, preciso de algumas informações, como seu nome completo e uma breve descrição do motivo pelo qual deseja falar com ela. Isso me ajudará a direcionar melhor sua solicitação.',
      id: '66e7295e01c2fd382315cef8',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:18.088Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Tiago Gouvêa, quero ver com ela sobre uma possível causa',
      id: '66e7297c557f78a14fbf0d79',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:48.887Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Entendido, Sra. Tiago Gouvêa. \n\nAs informações foram registradas e agora outra pessoa dará continuidade à sua solicitação. Por favor, aguarde um momento.',
      id: '66e7298118b2c053610b3efb',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:53.905Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
  ],
  id: '66e7280f557f78a14fbf0d74',
  assistantId: mockCoreAssistants[0].id,
  missionId: null,
  source: 'webAdmin',
  sourceUnique: null,
  conversationType: 'personUserTest',
  status: null,
  userInfo: {
    name: 'Tiago Gouvêa',
  },
  createdByUserId: '66d1a47d8e4a065d4e901096',
  userAgent:
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36',
  createdAt: '2024-09-15T18:31:43.903Z',
  updatedAt: '2024-09-15T18:39:25.596Z',
  lastMessageAt: '2024-09-15T18:37:53.905Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [mockCoreOrganizationUsers[1]],
};

// Criada pelo usuário do admin (com createdByUserId) com usuário identificado (userInfo), conversando com assistente + personAssistant + userInfo + webAdmin
export const personUserMockUserInfoAdmin: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 0,
      status: ThreadStatus.ONGOING,
      statusReason:
        'O usuário está aguardando o atendimento da Dra. Larissa para discutir a possível causa.',
      finishedStatus: null,
      finishedStatusReason: null,
      summary:
        'O usuário, Sra. Tiago Gouvêa, expressou interesse em discutir uma possível causa com a Dra. Larissa e forneceu informações necessárias para o encaminhamento.',
      createdAt: '2024-09-15T18:31:43.902Z',
      updatedAt: '2024-09-15T18:39:25.595Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content: 'Boa tarde',
      id: '66e72810557f78a14fbf0d75',
      channelMessageId: null,
      createdAt: '2024-09-15T18:31:44.281Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'errored',
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Como vai?',
      id: '66e72884557f78a14fbf0d76',
      channelMessageId: null,
      createdAt: '2024-09-15T18:33:40.152Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'errored',
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Tudo bem?',
      id: '66e72905557f78a14fbf0d77',
      channelMessageId: null,
      createdAt: '2024-09-15T18:35:50.116Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Boa tarde, Sra. \n\nEstou à disposição como secretária jurídica da Dra. Larissa Rodrigues. Para que eu possa direcionar o atendimento de forma mais eficiente, poderia me informar seu nome e o motivo do contato? Além disso, esta situação é uma emergência?',
      id: '66e7290701c2fd382315cef6',
      channelMessageId: null,
      createdAt: '2024-09-15T18:35:51.776Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Gostaria de falar com a Larissa.',
      id: '66e7295c557f78a14fbf0d78',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:16.646Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Sra., para que eu possa encaminhar seu pedido à Dra. Larissa, preciso de algumas informações, como seu nome completo e uma breve descrição do motivo pelo qual deseja falar com ela. Isso me ajudará a direcionar melhor sua solicitação.',
      id: '66e7295e01c2fd382315cef8',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:18.088Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Tiago Gouvêa, quero ver com ela sobre uma possível causa',
      id: '66e7297c557f78a14fbf0d79',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:48.887Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Entendido, Sra. Tiago Gouvêa. \n\nAs informações foram registradas e agora outra pessoa dará continuidade à sua solicitação. Por favor, aguarde um momento.',
      id: '66e7298118b2c053610b3efb',
      channelMessageId: null,
      createdAt: '2024-09-15T18:37:53.905Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
  ],
  id: '66e7280f557f78a14fbf0d74',
  assistantId: mockCoreAssistants[0].id,
  missionId: null,
  source: 'webAdmin',
  sourceUnique: null,
  conversationType: 'personUserTest',
  status: null,
  userInfo: {
    name: 'Tiago Gouvêa',
  },
  createdByUserId: '66d1a47d8e4a065d4e901096',
  userAgent:
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36',
  createdAt: '2024-09-15T18:31:43.903Z',
  updatedAt: '2024-09-15T18:39:25.596Z',
  lastMessageAt: '2024-09-15T18:37:53.905Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [mockCoreOrganizationUsers[1]],
};

export const mockChatListPersonUserThreads: Thread[] = [
  personUserTalkingUser,
  personUserAnonymousAdminUser,
  personUserMockUserInfoAdminUser,
];
