import { Thread, ThreadStatus } from '@/typings/backend';
import { mockCoreAssistants } from '../mockCoreWebsocketData';

// Usuário anonimo (sem userInfo) com o Assistente, vindo da web, primeiro caso issue
export const personAssistantAnonymousAssistant: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 0,
      status: ThreadStatus.JUST_STARED,
      statusReason: null,
      finishedStatus: null,
      finishedStatusReason: null,
      summary: null,
      createdAt: '2024-09-02T14:28:35.320Z',
      updatedAt: '2024-09-02T14:29:48.625Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content: 'Bom dia, preciso de uma advogado.',
      id: '66d5cb9328860381a5c9a01a',
      channelMessageId: null,
      createdAt: '2024-09-02T14:28:36.993Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Bom dia! Agradeço por entrar em contato. Sou Ana Laura, secretária jurídica da Dra. Larissa Rodrigues. Como o Sr(a) está? Poderia, por gentileza, me informar seu nome e uma breve descrição do motivo do contato? E essa situação é uma emergência?',
      id: '66d5cb9b3f09ab589a7f25cb',
      channelMessageId: null,
      createdAt: '2024-09-02T14:28:43.719Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Quero falar somente com a Dra Larissa',
      id: '66d5cbd728860381a5c9a01b',
      channelMessageId: null,
      createdAt: '2024-09-02T14:29:44.947Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Entendo, Sr(a). Contudo, a Dra. Larissa atende os clientes por agendamento prévio. Posso ajudá-lo(a) a agendar uma reunião com ela? Para isso, peço que me informe seu nome e o motivo do contato.',
      id: '66d5cbdc3f09ab589a7f25cd',
      channelMessageId: null,
      createdAt: '2024-09-02T14:29:48.625Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
  ],
  id: '66d5cb9228860381a5c9a019',
  assistantId: mockCoreAssistants[0].id,
  missionId: '66d5c542291f53c6e43b0384',
  source: 'webUser',
  sourceUnique: null,
  conversationType: 'personAssistantTest',
  status: 'QUEUED',
  userInfo: null,
  createdByUserId: null,
  userAgent:
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36',
  createdAt: '2024-09-02T14:28:35.320Z',
  updatedAt: '2024-09-02T14:30:24.858Z',
  lastMessageAt: '2024-09-02T14:29:48.625Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [],
};

// Usuário identificado (com `userInfo`) com o Assistente + `userInfo + webUser`, segundo caso issue
export const personAssistantUserAssistant: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 0,
      status: ThreadStatus.JUST_STARED,
      statusReason: null,
      finishedStatus: null,
      finishedStatusReason: null,
      summary: null,
      createdAt: '2024-09-08T17:38:23.580Z',
      updatedAt: '2024-09-08T17:38:54.426Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content:
        'Olá Dra. Larissa Rodrigues, tudo bem? espero que sim Meu nome é Rita sou advogada, sou advogada do Sr. Marcos e gostaria de conversar com a Sra. sobre uma forma de acordamos para finalização do processo. Sra teria horário disponível ou pode me passar seu endereço para que vá até a Sra?\n\nAguardo retorno',
      id: '66dde110be0ff7e8675e48b1',
      channelMessageId: null,
      createdAt: '2024-09-08T17:38:25.224Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Olá, Sra. Rita. A Dra. Larissa Rodrigues está disponível para discutir a finalização do processo. Para direcionar o atendimento de forma mais eficiente, por gentileza, poderia fornecer seu nome completo e um e-mail para contato? Assim, poderei verificar a melhor forma de agendar essa conversa.',
      id: '66dde113a4afbc26b390eb59',
      channelMessageId: null,
      createdAt: '2024-09-08T17:38:27.816Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'ishaiohsoa@gmail.com rita de cassia',
      id: '66dde12bbe0ff7e8675e48b2',
      channelMessageId: null,
      createdAt: '2024-09-08T17:38:51.880Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Agradeço pelas informações, Sra. Rita de Cássia. Vou verificar a disponibilidade da Dra. Larissa para agendar um horário para a conversa. Assim que tiver uma resposta, entrarei em contato.',
      id: '66dde12ea4afbc26b390eb5b',
      channelMessageId: null,
      createdAt: '2024-09-08T17:38:54.426Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
  ],
  id: '66dde10fbe0ff7e8675e48b0',
  assistantId: mockCoreAssistants[0].id,
  missionId: '66d5c542291f53c6e43b0384',
  source: 'webUser',
  sourceUnique: null,
  conversationType: 'personAssistantTest',
  status: 'QUEUED',
  userInfo: {
    name: 'Rita de Cássia',
    email: 'ishaiohsoa@gmail.com',
  },
  createdByUserId: null,
  userAgent:
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36',
  createdAt: '2024-09-08T17:38:23.580Z',
  updatedAt: '2024-09-08T17:38:54.894Z',
  lastMessageAt: '2024-09-08T17:38:54.426Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [],
};

//Criada pelo usuário do admin (com `createdByUserId`) com usuário anonimo, conversando com assistente, terceiro caso issue
export const personAssistantAnonymousAdminAssistant: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 0,
      status: ThreadStatus.JUST_STARED,
      statusReason: null,
      finishedStatus: null,
      finishedStatusReason: null,
      summary: null,
      createdAt: '2024-09-02T14:18:02.615Z',
      updatedAt: '2024-09-02T14:18:08.957Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content: 'Bom dia. Sou o Dr Ivan, advogado do Tiago Gouvêa e queremos negociar um acordo.',
      id: '66d5c91bc3201de844ca54bd',
      channelMessageId: null,
      createdAt: '2024-09-02T14:18:04.281Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Bom dia, Dr. Ivan. Agradeço por entrar em contato. Para que eu possa auxiliá-lo da melhor forma, poderia me informar o motivo específico da negociação do acordo? Além disso, gostaria de saber se a situação é uma emergência. Como o Sr. está?',
      id: '66d5c9203f09ab589a7f25c5',
      channelMessageId: null,
      createdAt: '2024-09-02T14:18:08.957Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
  ],
  id: '66d5c919c3201de844ca54bc',
  assistantId: mockCoreAssistants[0].id,
  missionId: '66d5c542291f53c6e43b0384',
  source: 'webAdmin',
  sourceUnique: null,
  conversationType: 'personAssistantTest',
  status: 'QUEUED',
  userInfo: null,
  createdByUserId: '66d1a47d8e4a065d4e901096',
  userAgent:
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36',
  createdAt: '2024-09-02T14:18:02.615Z',
  updatedAt: '2024-09-02T14:18:09.432Z',
  lastMessageAt: '2024-09-02T14:18:08.957Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [],
};

// Criada pelo usuário do admin (com `createdByUserId`) com usuário identificado (userInfo), conversando com assistente + `personAssistant + userInfo + webAdmin`, quarto caso issue
export const personAssistantUserAdminAssistant: Thread = {
  missions: [
    {
      missionId: '66d5c542291f53c6e43b0384',
      priority: 0,
      status: ThreadStatus.ONGOING,
      statusReason: null,
      finishedStatus: null,
      finishedStatusReason: null,
      summary: null,
      createdAt: '2024-09-02T14:01:57.032Z',
      updatedAt: '2024-09-02T14:06:50.375Z',
      finishedAt: null,
    },
  ],
  messages: [
    {
      role: 'user',
      internalRole: null,
      content: 'Bom dia.',
      id: '66d5c55534b7ef42cabc2f8a',
      channelMessageId: null,
      createdAt: '2024-09-02T14:01:58.701Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Bom dia! Agradeço por entrar em contato. Para direcionar nosso atendimento de forma mais eficiente, você poderia me informar seu nome e o motivo do contato? É uma emergência?',
      id: '66d5c5573f09ab589a7f25b2',
      channelMessageId: null,
      createdAt: '2024-09-02T14:01:59.655Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Preciso de um contrato para meus funcionários.',
      id: '66d5c60834b7ef42cabc2f8b',
      channelMessageId: null,
      createdAt: '2024-09-02T14:04:57.951Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Entendi. Para que eu possa ajudá-lo melhor, poderia me informar seu nome completo e, se possível, o número de funcionários para os quais você precisa do contrato? Assim, poderei passar as informações para a Dra. Larissa e agendarmos uma reunião.',
      id: '66d5c6103f09ab589a7f25b4',
      channelMessageId: null,
      createdAt: '2024-09-02T14:05:04.239Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Meu nome é tiago e tenho 10 colaboradores.',
      id: '66d5c63434b7ef42cabc2f8c',
      channelMessageId: null,
      createdAt: '2024-09-02T14:05:41.743Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Obrigado, Tiago. Com essas informações, podemos avançar. Poderia me fornecer um endereço de e-mail para que eu possa registrar seu contato e agendar uma reunião com a Dra. Larissa?',
      id: '66d5c6383f09ab589a7f25b6',
      channelMessageId: null,
      createdAt: '2024-09-02T14:05:44.530Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'tiago@appmasters.io',
      id: '66d5c64534b7ef42cabc2f8d',
      channelMessageId: null,
      createdAt: '2024-09-02T14:05:58.541Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Perfeito, Tiago! Já registrei suas informações. Agora, para agendarmos a reunião, você poderia me informar um horário que seja conveniente para você?',
      id: '66d5c6483f09ab589a7f25b8',
      channelMessageId: null,
      createdAt: '2024-09-02T14:06:00.762Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
    {
      role: 'user',
      internalRole: null,
      content: 'Amanha de manhã.',
      id: '66d5c67734b7ef42cabc2f8e',
      channelMessageId: null,
      createdAt: '2024-09-02T14:06:48.717Z',
      assistantId: mockCoreAssistants[0].id,
      userId: '66d1a47d8e4a065d4e901096',
      status: 'received',
    },
    {
      role: 'assistant',
      internalRole: null,
      content:
        'Amanhã de manhã, perfeito. Vou agendar uma reunião. Por favor, acesse o seguinte link para escolher o horário que melhor lhe convier: [Agendar Reunião](https://outlook.office.com/bookwithme/user/7ffe21aea77440fa844b5b9749a60615%40rodrigueseabreuadvocacia.com.br/meetingtype/cf6b95ea-1a14-4d96-bc77-d6c1c788dbb2?anonymous&isanonymous=true). \n\nSe precisar de mais alguma informação, é só avisar!',
      id: '66d5c67a3f09ab589a7f25ba',
      channelMessageId: null,
      createdAt: '2024-09-02T14:06:50.375Z',
      assistantId: mockCoreAssistants[0].id,
      userId: null,
      status: null,
    },
  ],
  id: '66d5c55434b7ef42cabc2f89',
  assistantId: mockCoreAssistants[0].id,
  missionId: '66d5c542291f53c6e43b0384',
  source: 'webAdmin',
  sourceUnique: null,
  conversationType: 'personAssistantTest',
  status: 'QUEUED',
  userInfo: {
    name: 'Tiago',
    email: 'tiago@appmasters.io',
  },
  createdByUserId: '66d1a47d8e4a065d4e901096',
  userAgent:
    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36',
  createdAt: '2024-09-02T14:01:57.032Z',
  updatedAt: '2024-09-02T14:06:50.853Z',
  lastMessageAt: '2024-09-02T14:06:50.375Z',
  tags: [],
  opened: true,
  isReady: true,
  mock: true,
  threadUsers: [],
};

export const mockChatListPersonAssistantThreads: Thread[] = [
  personAssistantAnonymousAssistant,
  personAssistantUserAssistant,
  personAssistantAnonymousAdminAssistant,
  personAssistantUserAdminAssistant,
];
