import useRollbar from '@/hooks/useRollbar';
import { deleteCookie, setCookie } from 'cookies-next';
import { useState } from 'react';

export default function useLocalStorage<T>({
  key,
  initialValue,
  useCookies,
}: {
  key: string;
  initialValue: T;
  useCookies?: boolean;
}) {
  const rollbar = useRollbar();

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      rollbar.error('Error on getting value from localStorage', { error });
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
        if (useCookies) setCookie(key, JSON.stringify(valueToStore), { maxAge });
      }
    } catch (error) {
      rollbar.error('Error on setValue of useLocalStorage', { error });
    }
  };

  const cleanStoredValue = () => {
    try {
      localStorage.removeItem(key);
      if (useCookies) deleteCookie(key);
      setStoredValue(initialValue);
    } catch (error) {
      rollbar.error('Error on cleanStoredValue', { error });
    }
  };

  return [storedValue, setValue, cleanStoredValue] as const;
}
