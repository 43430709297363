import { io } from 'socket.io-client';
import rollbar from './rollbar';
import { getErrorMessage } from './error';
import { env } from '@/env';

const url = env.NEXT_PUBLIC_SERVER_URL || ':3456';

type Args = { organizationId: string } | { assistantSlug: string };

function getSocket(params: Args) {
  try {
    let fullUrl: string;
    if ('organizationId' in params) {
      fullUrl = `${url}?organizationId=${params.organizationId}`;
    } else {
      fullUrl = `${url}?assistantSlug=${params.assistantSlug}`;
    }
    const socket = io(fullUrl);

    return socket;
  } catch (err) {
    rollbar.critical('Failed to create socket intance', {
      message: getErrorMessage(err),
      error: err,
    });
    throw err;
  }
}

export { getSocket };
